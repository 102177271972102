import Admin from '@/models/bread/Admin/chapter'

export default {
  key:'Chapter',
  name:{
    singular:'Chapter',
    plural:'Chapters',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:false,
  Teacher:false,
}