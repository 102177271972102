export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Key',
          value: 'key',
        },
        {
          text: 'Syllabus',
          value: 'Syllabus',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Sort',
          value: 'sort',
        },
        {
          text: 'Name',
          value: 'translatableName',
        },
        {
          text:'Data',
          value:'data-table-expand',
        },
        // {
        //   text: 'Data',
        //   value: 'data',
        // },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:false,
}